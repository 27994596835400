
import './App.css';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { lazy, Suspense } from "react";

const Locations = lazy(() => import("./views/Locations"));
const LocationDetail = lazy(() => import("./views/LocationDetail"));
const Groups = lazy(() => import("./views/Groups"));
const GroupDetail = lazy(() => import("./views/GroupDetail"));
const Users = lazy(() => import("./views/Users"));
const UserDetail = lazy(() => import("./views/UserDetail"));
const Login = lazy(() => import("./views/Login"));

function App() {

  var url = window.location.href.split("/");
  
  if (url[3] !== "login" && sessionStorage.length === 0) {
    window.location.href = "/login";
  }

  return (
    <div className="App">
      <Router>
        <Suspense fallback={<div className='loader-wrapper'><div className="loader"></div></div>}>
          <Routes>
            <Route exact path="/" element={<Locations />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/locations/:id" element={<LocationDetail />} />
            <Route exact path="/groups" element={<Groups />} />
            <Route exact path="/groups/:id" element={<GroupDetail />} />
            <Route exact path="/users" element={<Users />} />
            <Route exact path="/users/:id" element={<UserDetail />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
